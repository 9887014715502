import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";

//^ stylesheets
import classes from "../../../pages/pre-built/package-subscription/subscription-packages/SubscriptionPackage.module.scss";

//^ redux actions
import { servicePlanAction } from "../../../store/slices/service-slice";

//^ http request
import { getServiceDetails } from "../../../http/post-api";

//^ mui
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";

//^ components
import Title from "../../../components/title/Title";
import Switch from "../../../components/ui/switch/Switch";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../components/ui/modals/error-model/ErrorAlert";
import SubscriptionPackageSkeleton from "../../../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import Separator from "../../../components/ui/separator/Separator";
import { Icon } from "../../../components/Component";
import BackButton from "../../../components/button/back-button";
import EmptyFolder from "../../../components/svg/empty-folder";
import Head from "../../../layout/head/Head";

export function ServiceList({ keyName, value }) {
  return (
    <>
      <Stack gap={"0.525rem"} direction={"row"}>
        <Typography variant="subtitle1">{`${keyName}:`}</Typography>
        <Typography variant="subtitle1" fontWeight={600}>{`${value}`}</Typography>
      </Stack>
    </>
  );
}

export default function ServicePlans() {
  const { serviceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const servicePlanValidity = useSelector((state) => state.servicePlan.servicePlanValidity);

  const [subscribedData, setSubscribedData] = useState({});

  //^ service plan query fn
  const {
    data: serviceDetailsData,
    isLoading: serviceDetailsIsLoading,
    isRefetching: serviceDetailsIsRefetching,
    isError: serviceDetailsIsError,
    error: serviceDetailsError,
    refetch: serviceDetailsRefetch,
  } = useQuery({
    queryKey: ["get-service-details"],
    queryFn: () => getServiceDetails({ serviceId: parseInt(serviceId) }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!serviceDetailsIsLoading || !serviceDetailsIsRefetching) {
      if (!serviceDetailsData?.status) {
        if (serviceDetailsData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscribedData(serviceDetailsData?.data?.subscribedData);
      }
    }
  }, [serviceDetailsData, serviceDetailsIsLoading, serviceDetailsIsRefetching]);

  useEffect(() => {
    if (serviceDetailsIsError) {
      console.log(serviceDetailsError);
    }
  }, [serviceDetailsIsError, serviceDetailsError]);

  function servicePlanSwitchHandler() {
    dispatch(servicePlanAction.toggleServicePlanValidity());
  }

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  return (
    <>
      <Head title={"Service Plan"} />
      {serviceDetailsIsError && (
        <ErrorAlert
          title={`${serviceDetailsError?.code || 500}`}
          description={`${serviceDetailsError?.info?.message || "Something went wrong"}`}
          isConformed={() => serviceDetailsRefetch()}
        />
      )}
      <Stack gap={"1.25rem"}>
        <Title
          title={
            serviceDetailsIsLoading || serviceDetailsIsRefetching
              ? "Service Plans"
              : `${serviceDetailsData?.data?.services?.name} Plans`
          }
          endComponent={
            <Stack direction={"row"} gap={"1.5rem"} justifyContent={"space-between"} width={"100%"}>
              <Switch
                checkedLabel={"Monthly"}
                unCheckedLabel={"Yearly"}
                onClick={servicePlanSwitchHandler}
                checked={servicePlanValidity}
              />
              <BackButton title={"Services"} onClick={() => navigate("/services")} />
            </Stack>
          }
        />
        <Box paddingBottom={"2rem"}>
          {serviceDetailsIsLoading || serviceDetailsIsRefetching ? (
            <Box width={"100%"}>
              <SubscriptionPackageSkeleton />
            </Box>
          ) : serviceDetailsData?.data?.services?.plans?.length > 0 ? (
            <Grid container spacing={2.5}>
              {serviceDetailsData?.data?.services?.plans?.map((plan, index) => {
                const planPrice = servicePlanValidity ? plan.yearly_price : plan.monthly_price;
                const featureObj = JSON.parse(plan.features);
                const formattedFeatures = Object.keys(featureObj).map((title) => ({
                  title,
                  ...featureObj[title],
                }));

                const validity = servicePlanValidity ? 12 : 1;

                return (
                  <>
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        width={"100%"}
                        height={"100%"}
                        className={`${
                          subscribedData?.plan_id === plan.id &&
                          subscribedData?.validity === validity &&
                          plan.is_subscribed &&
                          "bg-primary d-flex flex-column"
                        }`}
                        style={
                          subscribedData?.plan_id === plan.id &&
                          subscribedData?.validity === validity &&
                          plan.is_subscribed
                            ? {
                                borderRadius: "1rem",
                                paddingRight: "0.225rem",
                                paddingLeft: "0.225rem",
                                paddingBottom: "0.225rem",
                              }
                            : {}
                        }
                      >
                        {subscribedData?.plan_id === plan.id &&
                        subscribedData?.validity === validity &&
                        plan.is_subscribed ? (
                          <Typography
                            textAlign={"center"}
                            variant="overline"
                            fontWeight={"600"}
                            color={"white"}
                            fontSize={15}
                            padding={"0.725rem 0rem"}
                          >
                            Current Subscription
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Paper
                          elevation={6}
                          className={`pricing ${plan.tags ? "recommend" : ""} pb-2 position-relative`}
                          sx={{ borderRadius: "1rem" }}
                        >
                          <div className={`p-2 ${classes["pricing-details"]}`}>
                            <div className="d-flex flex-column" style={{ gap: "1.25rem" }}>
                              <div className={`${classes["subscription-title"]}`}>
                                <div>
                                  <h5 className="title">{plan.name}</h5>
                                </div>
                                <div
                                  className={`${plan.is_subscribed ? "pt-2" : ""}`}
                                  style={{ width: "7rem", height: "7rem" }}
                                >
                                  <img src={`${process.env.REACT_APP_API_URL}${plan.logo}`} alt={plan.name} />
                                </div>
                              </div>
                              <div className="pricing-amount px-2">
                                <div className="amount">
                                  ${planPrice}{" "}
                                  <span style={{ fontSize: "12px" }}>{`/ ${!servicePlanValidity ? "mo" : "yr"}`}</span>
                                </div>
                                <Separator />

                                <div>
                                  <p>Includes</p>
                                  <p className="d-flex flex-column fs-5" style={{ gap: "1rem" }}>
                                    {formattedFeatures.length > 0 ? (
                                      <Stack gap={"0.625rem"}>
                                        {formattedFeatures.map((service, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <ServiceList keyName={service.title} value={service.value} />
                                            </React.Fragment>
                                          );
                                        })}
                                      </Stack>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="pricing-action d-flex justify-content-center">
                              <motion.div whileHover={"hover"}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={() => navigate(`${plan.id}`)}
                                >
                                  <Stack gap={"0.625rem"} alignItems={"center"} direction={"row"}>
                                    <Typography variant="subtitle2" component={"span"}>
                                      {`${
                                        subscribedData.current_subscribed
                                          ? subscribedData?.price >= planPrice
                                            ? subscribedData?.plan_id === plan.id &&
                                              subscribedData?.validity === validity &&
                                              plan.is_subscribed
                                              ? "Renew Plan"
                                              : "Buy Plan"
                                            : "Upgrade Plan"
                                          : "Buy Plan"
                                      }`}
                                    </Typography>
                                    <motion.span
                                      variants={textMotion}
                                      className="d-flex justify-content-center align-plans-center"
                                    >
                                      <Icon name="arrow-right" />
                                    </motion.span>
                                  </Stack>
                                </Button>
                              </motion.div>
                            </div>
                          </div>
                        </Paper>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          ) : (
            <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
              <EmptyFolder />
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
}
