import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//^ UI
import { Tooltip } from "reactstrap";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//^ utility functions
import { getCookie } from "../../../utils/Utils";
import { toast } from "react-toastify";

//^ payment actions
import { clickPaymentToken, setPaymentModal, setPaymentSlice, setSection } from "../../../store/slices/payment-slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paperWidthSm": {
    width: "100%",
    maxWidth: "600px",
  },
}));

const Payment = () => {
  const [tooltipInfoOpen, setTooltipTwoFAOpen] = useState(false);
  const toggleTooltipInfo = () => setTooltipTwoFAOpen(!tooltipInfoOpen);

  const payment = useSelector((store) => store.payment);
  const users_account_tokens = useSelector((store) => store.payment.account_token);

  const handleClose = () => {
    dispatch(setPaymentModal(!paymentModal));
  };

  const {
    paymentModal,
    paymentModalTitle,
    token_value,
    payWithTokens,
    orderDescription,
    price,
    auger_fee,
    payWithTokensSpinner,
    section,
  } = payment;

  const totalPrice = price + (price * auger_fee) / 100;

  const [inputValue, setInputValue] = useState({
    usd: parseFloat(totalPrice.toFixed(2)),
    account_tokens: price / token_value,
  });

  const payWithTokenRef = useRef(null);

  useEffect(() => {
    dispatch(setSection("accountTokens"));
    let tempusd = inputValue.usd || price;
    setInputValue({ ...inputValue, account_tokens: tempusd / token_value });
    // eslint-disable-next-line
  }, []);

  const onError = (_data, _actions) => {
    toast.error("An Error occurred with your payment");
  };
  const dispatch = useDispatch();

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      addPayment(details);
    });
  };
  const purchaseToken = async (responsePaymentId) => {
    if (responsePaymentId) {
      let formData = new FormData();
      formData.append("payment_id", responsePaymentId);
      let fetRes = await fetch(`${process.env.REACT_APP_API_URL}api/v1/wallet/buy`, {
        method: "POST",
        cache: "no-store",
        body: formData,
        headers: {
          authToken: getCookie("authToken", null),
        },
      });
      const res = await fetRes.json();
      if (res.status) {
        toast.success(res.message);
        const newPaymentState = {
          users_account_tokens: res.data.account_tokens,
          section: "accountTokens",
        };
        dispatch(setPaymentSlice(newPaymentState));
        payWithTokens();
        payWithTokenRef?.current?.click();
        dispatch(clickPaymentToken());
      } else {
        toast.error(res.message);
      }
    } else {
      toast.error("Invalid payment id");
    }
  };
  const addPayment = async (payment_response) => {
    let formData = new FormData();
    formData.append("amount", payment_response?.purchase_units[0]?.amount?.value);
    formData.append("note", "package purchase");
    formData.append("payment_txn_id", payment_response?.purchase_units[0]?.payments?.captures[0]?.id);
    formData.append("payment_response", JSON.stringify(payment_response));
    formData.append("status", 3);
    formData.append("mode", 3);
    formData.append("purpose", 2);
    let fetRes = await fetch(`${process.env.REACT_APP_API_URL}api/v1/payment/add`, {
      method: "POST",
      cache: "no-store",
      body: formData,
      headers: {
        authToken: getCookie("authToken", null),
      },
    });
    const res = await fetRes.json();

    if (res.status) {
      // toast.success(res.message);
      purchaseToken(res.data.payment_id);
    } else {
      toast.error(res.message);
    }
  };
  const createOrder = (_data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: orderDescription,
            amount: {
              currency_code: "USD",
              value: parseFloat(inputValue.usd.toFixed(2)) || price,
            },
            payee: {
              email_address: getCookie("email", "joe@example.com"),
              merchant_id: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog onClose={handleClose} aria-labelledby="payment-dialog-title" open={paymentModal}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="payment-dialog-title">
            {paymentModalTitle}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  name="payment-option"
                  id="payment-option-paypal"
                  data-payment-type="paypal"
                  onClick={(e) => {
                    setInputValue({ ...inputValue, usd: parseFloat(totalPrice.toFixed(2)) });
                    e.target.checked && dispatch(setSection("paypal"));
                  }}
                  checked={section === "paypal"}
                  control={<Radio />}
                  label="Paypal"
                />
                <FormControlLabel
                  name="payment-option"
                  id="payment-option-account-tokens"
                  data-payment-type="accountTokens"
                  onClick={(e) => e.target.checked && dispatch(setSection("accountTokens"))}
                  checked={section === "accountTokens"}
                  control={<Radio />}
                  label="Account Tokens"
                />
              </RadioGroup>
            </FormControl>

            {section !== "accountTokens" && (
              <Box sx={{ width: "100%", padding: "1.25rem" }}>
                <Stack gap={"1rem"} justifyContent={"center"} alignItems={"center"}>
                  <Stack direction={"row"} gap={"0.325rem"}>
                    <Typography fontWeight={"600"}>{"Live Token Rate: 1 SBC"}</Typography>
                    <Typography>{`$${token_value.toFixed(6)}`}</Typography>
                  </Stack>

                  <Stack direction={"row"} gap={"0.325rem"}>
                    <Typography fontWeight={"600"}>{"Purchase Amount($):"}</Typography>
                    <Typography>{inputValue.usd}</Typography>
                  </Stack>
                </Stack>
              </Box>
            )}

            <Stack alignItems={"center"} width={"100%"}>
              {section === "paypal" && (
                <>
                  <div id="paypal-payment" className="col-md-4 pt-1 text-center">
                    <div id="paypal-button" className="m-auto">
                      <PayPalScriptProvider
                        options={{
                          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        }}
                      >
                        <PayPalButtons
                          style={{ layout: "horizontal" }}
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </div>
                </>
              )}
              {section === "accountTokens" && (
                <>
                  <Box width={"100%"} padding={"1.25rem 0.625rem"}>
                    <Stack gap={"1rem"}>
                      {" "}
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <Stack direction="row" gap="0.325rem">
                              <Typography fontWeight={"600"} whiteSpace={"nowrap"}>
                                Available Tokens:
                              </Typography>
                              <Typography>{users_account_tokens.toFixed(6)}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Stack direction="row" gap="0.325rem">
                              <Typography fontWeight={"600"}>{"Auger Fee(2.9%):"}</Typography>
                              <Typography>{((price * auger_fee) / 100 / token_value).toFixed(6)}</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Stack direction={"row"} gap={"0.625rem"}>
                              <Typography>
                                <Typography fontWeight={"600"}>
                                  <Stack direction={"row"} gap={"0.325rem"}>
                                    <span style={{ whiteSpace: "nowrap" }}>Required Tokens</span>
                                    <span id="required-token">
                                      <InfoOutlinedIcon fontSize="20" />
                                    </span>
                                    <span>:</span>
                                  </Stack>
                                </Typography>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipInfoOpen}
                                  target="required-token"
                                  toggle={toggleTooltipInfo}
                                >
                                  Required tokens with Auger Fee
                                </Tooltip>
                              </Typography>
                              <Typography>{((price + (price * auger_fee) / 100) / token_value).toFixed(6)}</Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            {section === "accountTokens" && (
              <Box>
                {(price + (price * auger_fee) / 100) / token_value >= users_account_tokens ? (
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => {
                      let tempUsdInputValue = parseFloat((price + (price * auger_fee) / 100).toFixed(2));

                      setInputValue({ ...inputValue, usd: tempUsdInputValue });
                      dispatch(setSection("paypal"));
                    }}
                  >
                    Purchase tokens
                  </Button>
                ) : (
                  <Box>
                    <Button
                      autoFocus
                      variant="contained"
                      ref={payWithTokenRef}
                      type="button"
                      onClick={payWithTokens}
                      disabled={payWithTokensSpinner}
                      endIcon={payWithTokensSpinner ? <CircularProgress size={18} color="inherit" /> : ""}
                    >
                      Pay with Tokens
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            <Button color="secondary" variant="contained" onClick={handleClose}>
              close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default Payment;
