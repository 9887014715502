import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

//^ mui
import { Box, Stack, Typography, Button, CircularProgress } from "@mui/material";

//^ http request
import { postSet2FAProfileHandler } from "../../../../../http/post-api";

//^ component
import AuthOtp from "../../../../../components/ui/auth-otp/AuthOtp";
import { Icon } from "../../../../../components/Component";
import ErrorModel from "../../../../../components/ui/modals/error-model/ErrorModel";
import { getCookie } from "../../../../../utils/Utils";
import ResendOtpTimer from "../components/ResendOtpTimer";

export default function EmailTwoFactorAuth() {
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [sendMailOtpData, setSendMailOtpData] = useState(undefined);
  // eslint-disable-next-line
  const [isCompleted, setIsCompleted] = useState(null);
  const [showOtpTimer, setShowOtpTimer] = useState(false);

  //^ error model
  const [sendMailErrorMode, setSendMailErrorMode] = useState(false);

  //^ post to verify email and send otp
  const {
    isPending: sendMailOtpIsPending,
    isError: sendMailOtpIsError,
    error: sendMailOtpError,
    mutate: sendMailOtpMutate,
    reset: sendMailOtpReset,
  } = useMutation({
    mutationKey: ["send-mail-otp-2fa-1"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.type === "error") {
          toast.error(data?.message);
        } else if (data.type === "success") {
          setIsInputDisabled(true);
          setShowOtpTimer(true);
          setSendMailOtpData(data);

          toast.success(data?.message);
        }

        if (data.message === "Validation failed") {
          toast.error(data?.message);
        }
      }

      sendMailOtpReset();
    },
  });

  useEffect(() => {
    if (sendMailOtpIsError) {
      setIsInputDisabled(true);
    }
  }, [sendMailOtpError, sendMailOtpIsError]);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const emailData = getCookie("email", "john@mail.io");

  const formik = useFormik({
    initialValues: {
      email: emailData,
    },
    validationSchema: schema,
    onSubmit(values) {
      sendMailOtpMutate({ twoFactAuth: "1", twoFactEmail: values.email });
      setIsInputDisabled(true);
    },
  });

  const toggleOtpRequestModelFail = () => {
    setSendMailErrorMode(!sendMailErrorMode);
  };

  return (
    <>
      <ErrorModel
        isOpen={sendMailErrorMode}
        toggleOpen={toggleOtpRequestModelFail}
        title={"Unable to send a otp request"}
        description={"Something went wrong"}
        onConfirmed={() => {
          toggleOtpRequestModelFail();
          sendMailOtpReset();
        }}
      />

      <Stack gap={"2rem"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              width={"100%"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box className="w-100 d-flex flex-column" style={{ gap: "0.252rem" }}>
                <input
                  type="text"
                  className={`form-control form-control-sm w-100 ${
                    formik.errors.email && formik.touched.email ? "is-invalid" : ""
                  }`}
                  id="email-input"
                  aria-describedby="validationServer03Feedback"
                  label="Email"
                  name="email"
                  defaultValue={formik.values.email}
                  style={{ flex: 1, width: "100%", height: "2.5rem", fontSize: "14px" }}
                  placeholder="Enter a Email ID."
                  onClick={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isInputDisabled}
                  required
                />
                {formik.errors.email && formik.touched.email ? (
                  <Typography variant="subtitle2" className="text-danger">
                    {formik.errors.email}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  sx={{ height: "2.5rem" }}
                  type="button"
                  size="large"
                  onClick={() => setIsInputDisabled(!isInputDisabled)}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
              <Button
                variant="contained"
                type={"submit"}
                color="primary"
                className={"d-flex"}
                style={{
                  gap: "0.5rem",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: sendMailOtpIsPending ? "not-allowed" : "",
                  whiteSpace: "nowrap",
                }}
                startIcon={sendMailOtpIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                disabled={sendMailOtpIsPending || !formik.isValid || isCompleted === false ? true : false}
              >
                <span>
                  {sendMailOtpData && sendMailOtpData?.code === 200 && sendMailOtpData?.type === "success"
                    ? "Resend OTP"
                    : "Send OTP"}
                </span>
              </Button>
              <Box>
                {showOtpTimer ? (
                  <ResendOtpTimer
                    onOtpTimer={(isCompleted) => {
                      if (isCompleted) {
                        sendMailOtpReset();
                        setShowOtpTimer(false);
                        setIsCompleted(isCompleted);
                      } else {
                        setIsCompleted(isCompleted);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
          </Stack>
        </form>
        {sendMailOtpData && sendMailOtpData?.type === "success" ? (
          <AuthOtp otpSendOn={sendMailOtpData?.data.email} type="email" email={formik.values.email} />
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
