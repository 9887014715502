export default function UploadFileSvg() {
  return (
    <svg width="585" height="379" viewBox="0 0 585 379" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="upload-file 1" clipPath="url(#clip0_108_73)">
        <path
          id="Vector"
          d="M571.25 327.91C571.05 337.71 570.25 347.48 569.33 357.23C569.303 357.413 569.339 357.599 569.43 357.76L349.43 357.83L129.64 357.91H6.63996C0.91996 344.03 3.26996 327.2 12.06 315.3C21.66 302.3 38.85 300.1 48 286.55C56.49 274.01 57.71 257.7 59.18 242.86C60.63 228.19 63.18 211.21 73.87 200.54C86.55 187.85 105.06 191.94 120.87 190.54C135.81 189.18 147.98 181.27 156.79 168.66C177.63 138.77 171.32 99.11 184.5 66.15C190.71 50.6 201.33 37.56 216.73 31.74C231.73 26.07 248.32 27.11 263.64 30.62C280.64 34.52 296.53 41.96 312.89 47.83C321.485 51.0445 330.341 53.5136 339.36 55.21C348.263 56.6021 357.303 56.9077 366.28 56.12C375.28 55.49 384.28 54.85 393.13 56.85C400.414 58.5242 407.338 61.4948 413.57 65.62C426.768 74.279 436.523 87.2786 441.15 102.37C449.86 131.27 440.96 167.03 460.76 191.83C479.48 215.28 512.55 214.74 535.3 231.83C564.11 253.46 571.97 292.91 571.25 327.91Z"
          fill="#F9DBDD"
        />
        <path
          id="Vector_2"
          d="M0 357.25L584.06 356.51C584.06 356.51 517.45 378.91 279.62 378.91C41.79 378.91 0 357.25 0 357.25Z"
          fill="#E8E8E8"
        />
        <path
          id="Vector_3"
          d="M125.26 356.91H434.26C434.26 356.91 475.89 236.65 377.74 243.53C377.74 243.53 369.53 161.03 284.55 161.03C199.57 161.03 187.55 250.79 187.55 250.79C187.55 250.79 85.5799 221.76 125.26 356.91Z"
          fill="#FFFEFD"
        />
        <path
          id="Vector_4"
          d="M434.22 356.91H224.22C322.42 353.96 287.69 284.52 287.69 284.52C392.59 264.15 337.8 175.77 337.8 175.77C373.32 199.48 377.7 243.53 377.7 243.53C475.84 236.66 434.22 356.91 434.22 356.91Z"
          fill="#F2E4E4"
        />
        <path
          id="Vector_5"
          d="M347.86 203.72L339.23 209.5C337.938 207.7 336.514 205.999 334.97 204.41C333.092 202.426 331.088 200.566 328.97 198.84L328.61 198.54C319.686 191.323 309.068 186.504 297.76 184.54H297.42C293.475 183.877 289.481 183.542 285.48 183.54H284.99C282.37 183.54 279.867 183.627 277.48 183.8C273.971 184.03 270.478 184.464 267.02 185.1C266.07 185.27 265.13 185.46 264.22 185.67C262.68 186 261.22 186.38 259.75 186.78C256.536 187.68 253.388 188.803 250.33 190.14C247.889 191.206 245.518 192.425 243.23 193.79L242.23 194.39C240.369 195.558 238.573 196.826 236.85 198.19C235.402 199.349 234.017 200.584 232.7 201.89C232.43 202.15 232.17 202.41 231.93 202.67C229.997 204.599 228.317 206.767 226.93 209.12L217.75 204.26C219.662 200.955 222.017 197.927 224.75 195.26C225.93 194.05 227.27 192.77 228.8 191.47C230.918 189.659 233.153 187.988 235.49 186.47C236.49 185.81 237.49 185.16 238.63 184.47C239.77 183.78 241.01 183.12 242.28 182.47C247.716 179.717 253.45 177.596 259.37 176.15C259.81 176.04 260.26 175.93 260.73 175.83C261.88 175.56 263.06 175.3 264.26 175.07C267.567 174.43 270.905 173.959 274.26 173.66C276.04 173.49 277.9 173.37 279.79 173.29L281.34 173.23C282.56 173.23 283.8 173.17 285.09 173.17H286.43C287.96 173.17 289.43 173.26 290.9 173.36C291.9 173.43 292.9 173.51 293.9 173.61H294.45C308.201 175.068 321.317 180.159 332.45 188.36C333.8 189.36 335.07 190.36 336.24 191.36C338.341 193.105 340.344 194.965 342.24 196.93C344.292 199.04 346.171 201.31 347.86 203.72Z"
          fill="#6B2B2B"
        />
        <path
          id="Vector_6"
          d="M175.91 263.05C173.217 262.06 170.339 261.674 167.48 261.92C159.549 262.162 151.831 264.55 145.15 268.83C136.129 274.684 129.642 283.72 126.98 294.14C125.499 299.95 125.231 306.002 126.19 311.92C126.366 312.557 126.785 313.099 127.358 313.429C127.931 313.758 128.611 313.849 129.25 313.68C129.882 313.495 130.418 313.074 130.748 312.505C131.078 311.935 131.175 311.26 131.02 310.62C129.664 301.437 131.659 292.073 136.64 284.24C141.646 276.5 149.311 270.858 158.19 268.38C161.923 267.293 165.804 266.801 169.69 266.92C170.948 266.846 172.208 267.002 173.41 267.38C173.991 267.687 174.666 267.763 175.301 267.592C175.935 267.422 176.482 267.017 176.83 266.46C177.157 265.885 177.243 265.204 177.071 264.566C176.899 263.927 176.482 263.383 175.91 263.05Z"
          fill="#E4F4F4"
        />
        <path
          id="Vector_7"
          d="M134.49 342.47C132.77 336.987 131.841 331.286 131.73 325.54C131.73 324.877 131.467 324.241 130.998 323.772C130.529 323.303 129.893 323.04 129.23 323.04C128.567 323.04 127.931 323.303 127.462 323.772C126.993 324.241 126.73 324.877 126.73 325.54C126.837 331.715 127.824 337.843 129.66 343.74C129.832 344.381 130.252 344.926 130.827 345.257C131.402 345.588 132.084 345.677 132.725 345.505C133.365 345.333 133.911 344.913 134.242 344.338C134.573 343.763 134.662 343.081 134.49 342.44V342.47Z"
          fill="#E4F4F4"
        />
        <path
          id="Vector_8"
          d="M213.25 217.01C204.399 226.787 198.723 239.019 196.97 252.09C196.826 252.731 196.931 253.402 197.264 253.968C197.596 254.534 198.131 254.953 198.76 255.14C199.94 255.44 201.64 254.69 201.81 253.35C203.36 241.162 208.574 229.731 216.76 220.57C218.91 218.19 215.41 214.62 213.25 217.01Z"
          fill="#E4F4F4"
        />
        <path
          id="Vector_9"
          d="M298.18 275.41H291.25V300.29C291.25 301.937 290.596 303.517 289.431 304.681C288.266 305.846 286.687 306.5 285.04 306.5H279.9C278.253 306.5 276.673 305.846 275.509 304.681C274.344 303.517 273.69 301.937 273.69 300.29V275.41H266.76C266.141 275.407 265.535 275.238 265.003 274.921C264.472 274.603 264.036 274.149 263.739 273.606C263.443 273.063 263.298 272.45 263.319 271.832C263.341 271.213 263.527 270.612 263.86 270.09L279.57 245.67C279.882 245.185 280.312 244.786 280.818 244.51C281.325 244.234 281.893 244.089 282.47 244.089C283.047 244.089 283.615 244.234 284.121 244.51C284.628 244.786 285.057 245.185 285.37 245.67L301.06 270.09C301.395 270.61 301.584 271.21 301.608 271.828C301.632 272.446 301.489 273.059 301.195 273.603C300.9 274.146 300.465 274.601 299.935 274.919C299.404 275.237 298.798 275.407 298.18 275.41Z"
          fill="#46C1B5"
        />
        <path
          id="Vector_10"
          d="M347.73 327.36H217.85V298.19C217.85 297.105 218.281 296.065 219.048 295.298C219.815 294.531 220.855 294.1 221.94 294.1H225.75C226.836 294.1 227.877 294.531 228.646 295.297C229.414 296.064 229.847 297.104 229.85 298.19V315.36H335.73V298.19C335.733 297.104 336.166 296.064 336.934 295.297C337.703 294.531 338.744 294.1 339.83 294.1H343.64C344.725 294.1 345.765 294.531 346.532 295.298C347.299 296.065 347.73 297.105 347.73 298.19V327.36Z"
          fill="#46C1B5"
        />
        <path
          id="Vector_11"
          d="M298.18 277.51H291.25V302.39C291.25 304.037 290.595 305.617 289.431 306.781C288.266 307.946 286.687 308.6 285.04 308.6H279.9C278.253 308.6 276.673 307.946 275.509 306.781C274.344 305.617 273.69 304.037 273.69 302.39V277.51H266.76C266.141 277.509 265.534 277.342 265.003 277.026C264.471 276.71 264.034 276.257 263.738 275.714C263.441 275.171 263.296 274.558 263.318 273.94C263.339 273.322 263.526 272.721 263.86 272.2L279.57 247.77C279.882 247.285 280.312 246.886 280.818 246.61C281.325 246.334 281.893 246.189 282.47 246.189C283.047 246.189 283.615 246.334 284.121 246.61C284.628 246.886 285.057 247.285 285.37 247.77L301.06 272.2C301.392 272.72 301.58 273.319 301.602 273.935C301.624 274.552 301.481 275.163 301.187 275.705C300.893 276.247 300.459 276.701 299.93 277.018C299.401 277.336 298.797 277.505 298.18 277.51Z"
          fill="#D65656"
        />
        <path
          id="Vector_12"
          d="M347.73 329.46H217.85V300.3C217.849 299.762 217.954 299.229 218.159 298.732C218.364 298.234 218.665 297.782 219.045 297.401C219.424 297.021 219.876 296.718 220.373 296.512C220.869 296.306 221.402 296.2 221.94 296.2H225.75C226.837 296.2 227.88 296.632 228.649 297.401C229.418 298.17 229.85 299.213 229.85 300.3V317.46H335.73V300.3C335.73 299.213 336.162 298.17 336.931 297.401C337.7 296.632 338.743 296.2 339.83 296.2H343.64C344.178 296.2 344.711 296.306 345.208 296.512C345.704 296.718 346.156 297.021 346.536 297.401C346.916 297.782 347.217 298.234 347.422 298.732C347.627 299.229 347.731 299.762 347.73 300.3V329.46Z"
          fill="#D65656"
        />
        <path
          id="Vector_13"
          d="M269.64 89.5L220.69 141.14C229.437 139.722 238.366 139.847 247.07 141.51C251.31 142.31 255.68 143.59 258.69 146.66C262.69 150.75 263.4 156.92 263.69 162.66C263.93 166.773 264.087 170.883 264.16 174.99C264.16 176.47 264.16 177.99 264.16 179.43H264.76C269.76 179.26 274.76 179.06 279.76 178.88C286.85 178.63 294.04 178.39 300.76 180.43C305.971 181.899 310.53 185.093 313.69 189.49C318.997 191.87 323.975 194.925 328.5 198.58L328.86 198.88L336.01 191.34L355.47 170.91L269.64 89.5ZM267.44 118.5C269.683 116.13 272.578 114.479 275.759 113.755C278.941 113.031 282.265 113.268 285.313 114.434C288.36 115.601 290.992 117.645 292.877 120.308C294.762 122.971 295.814 126.134 295.901 129.396C295.988 132.657 295.105 135.871 293.365 138.631C291.624 141.391 289.104 143.572 286.124 144.899C283.143 146.226 279.835 146.639 276.62 146.085C273.404 145.532 270.425 144.037 268.06 141.79C264.887 138.781 263.039 134.635 262.923 130.263C262.807 125.892 264.431 121.654 267.44 118.48V118.5Z"
          fill="#DCDCEA"
        />
        <path
          id="Vector_14"
          d="M273.77 85.15L268.69 80.33L200.06 152.74L235.46 186.31L243.26 193.71C245.548 192.345 247.919 191.126 250.36 190.06L242.25 182.37L210.32 152.1L220.72 141.1L269.67 89.46L355.47 170.91L336.12 191.33L328.97 198.87C331.088 200.596 333.092 202.456 334.97 204.44L342.12 196.9L365.55 172.19L273.77 85.15Z"
          fill="#F6FAFD"
        />
        <path
          id="Vector_15"
          d="M313.8 189.45C308.688 187.166 303.314 185.521 297.8 184.55H297.46C293.514 183.887 289.521 183.552 285.52 183.55H285.03C282.41 183.55 279.906 183.637 277.52 183.81C274.011 184.04 270.518 184.474 267.06 185.11C266.11 185.28 265.17 185.47 264.26 185.68C264.26 183.62 264.26 181.56 264.26 179.5H264.86C269.86 179.33 274.86 179.13 279.86 178.95C286.95 178.7 294.14 178.46 300.86 180.5C306.059 181.938 310.62 185.092 313.8 189.45Z"
          fill="#9595BF"
        />
        <path
          id="Vector_16"
          d="M264.24 179.41C264.24 181.47 264.24 183.53 264.24 185.59C262.7 185.92 261.24 186.3 259.77 186.7C256.556 187.6 253.408 188.723 250.35 190.06L242.24 182.37L210.31 152.1L220.71 141.1C229.458 139.682 238.386 139.807 247.09 141.47C251.33 142.27 255.7 143.55 258.71 146.62C262.71 150.71 263.42 156.88 263.71 162.62C263.95 166.733 264.107 170.843 264.18 174.95C264.23 176.45 264.24 177.91 264.24 179.41Z"
          fill="#4E4F94"
        />
        <path
          id="Vector_17"
          d="M290.76 117.83C293.132 120.072 294.785 122.967 295.512 126.149C296.238 129.331 296.004 132.657 294.84 135.706C293.676 138.755 291.634 141.39 288.972 143.278C286.31 145.167 283.147 146.223 279.885 146.314C276.623 146.405 273.406 145.526 270.643 143.789C267.88 142.052 265.694 139.535 264.362 136.555C263.03 133.576 262.612 130.268 263.159 127.051C263.707 123.833 265.197 120.851 267.44 118.48C268.928 116.904 270.711 115.637 272.689 114.752C274.666 113.866 276.799 113.379 278.965 113.319C281.131 113.258 283.288 113.626 285.312 114.4C287.336 115.174 289.187 116.34 290.76 117.83Z"
          fill="#9595BF"
        />
        <path
          id="Vector_18"
          d="M465.78 211.72L470.06 216.01L466.57 219.5L462.28 215.22L458.01 219.5L454.51 216.01L458.79 211.72L454.48 207.41L457.97 203.92L462.28 208.23L466.57 203.95L470.06 207.45L465.78 211.72Z"
          fill="#FFFEFD"
        />
        <path
          id="Vector_19"
          d="M520.48 224.32L543.89 243.46L524.75 266.91L501.34 247.77L520.48 224.32ZM541.57 243.69L520.71 226.69L503.71 247.55L524.57 264.6L541.57 243.69ZM533.57 244.5L521.57 234.69L511.75 246.69L523.75 256.5L533.57 244.5Z"
          fill="#FFFEFD"
        />
        <path
          id="Vector_20"
          d="M521.296 232.386L509.393 246.938L523.945 258.841L535.848 244.288L521.296 232.386Z"
          fill="#FFFEFD"
        />
        <path
          id="Vector_21"
          d="M548.53 185.05C545.311 185.052 542.164 184.099 539.487 182.312C536.81 180.525 534.723 177.984 533.49 175.011C532.258 172.038 531.935 168.765 532.562 165.608C533.19 162.451 534.739 159.551 537.015 157.275C539.291 154.999 542.191 153.45 545.348 152.822C548.505 152.195 551.777 152.518 554.751 153.75C557.724 154.983 560.265 157.07 562.052 159.747C563.839 162.424 564.792 165.571 564.79 168.79C564.785 173.101 563.07 177.234 560.022 180.282C556.974 183.33 552.841 185.045 548.53 185.05ZM538.34 168.79C538.342 170.805 538.941 172.774 540.062 174.449C541.183 176.123 542.775 177.427 544.637 178.197C546.5 178.967 548.548 179.167 550.524 178.773C552.5 178.379 554.315 177.407 555.739 175.982C557.163 174.556 558.133 172.741 558.525 170.764C558.917 168.788 558.715 166.74 557.943 164.878C557.172 163.017 555.866 161.426 554.19 160.307C552.515 159.187 550.545 158.59 548.53 158.59C547.191 158.59 545.865 158.854 544.628 159.367C543.391 159.879 542.267 160.631 541.321 161.578C540.375 162.525 539.624 163.65 539.113 164.887C538.601 166.125 538.339 167.451 538.34 168.79Z"
          fill="#DE7878"
        />
        <path
          id="Vector_22"
          d="M493.48 196.91C491.589 196.912 489.74 196.353 488.166 195.304C486.593 194.254 485.366 192.762 484.641 191.015C483.916 189.269 483.725 187.346 484.093 185.491C484.46 183.636 485.37 181.932 486.707 180.594C488.043 179.256 489.747 178.344 491.601 177.974C493.456 177.605 495.379 177.794 497.126 178.517C498.874 179.24 500.367 180.465 501.418 182.038C502.469 183.61 503.03 185.459 503.03 187.35C503.027 189.883 502.021 192.312 500.231 194.104C498.441 195.896 496.013 196.905 493.48 196.91ZM487.48 187.35C487.48 188.537 487.832 189.697 488.491 190.683C489.151 191.67 490.088 192.439 491.184 192.893C492.28 193.347 493.487 193.466 494.651 193.235C495.815 193.003 496.884 192.432 497.723 191.593C498.562 190.754 499.133 189.684 499.365 188.521C499.596 187.357 499.478 186.15 499.023 185.054C498.569 183.958 497.8 183.021 496.814 182.361C495.827 181.702 494.667 181.35 493.48 181.35C491.899 181.353 490.383 181.979 489.262 183.093C488.14 184.207 487.503 185.719 487.49 187.3L487.48 187.35Z"
          fill="#D65656"
        />
        <path
          id="Vector_23"
          d="M555.497 210.408L566.459 217.975L574.026 207.014L563.065 199.446L555.497 210.408Z"
          fill="#BBBAD0"
        />
        <path
          id="Vector_24"
          d="M509.01 159.02C513.495 159.02 517.13 155.385 517.13 150.9C517.13 146.415 513.495 142.78 509.01 142.78C504.526 142.78 500.89 146.415 500.89 150.9C500.89 155.385 504.526 159.02 509.01 159.02Z"
          fill="#7675A1"
        />
        <path
          id="Vector_25"
          d="M43.9302 175.14L35.7002 183.38L43.8702 191.57L37.1902 198.24L29.0602 190.06L20.8802 198.24L14.2002 191.57L22.3902 183.38L14.2002 175.21L20.8802 168.54L29.0602 176.71L37.3002 168.47L43.9302 175.14ZM20.8402 170.76L16.3902 175.21L24.5702 183.38L16.3902 191.57L20.8402 196.02L29.0202 187.83L37.1902 196.02L41.6502 191.57L33.4702 183.38L41.7102 175.14L37.2602 170.69L29.0602 178.91L20.8402 170.76Z"
          fill="#D65656"
        />
        <path
          id="Vector_26"
          d="M159.43 224.01L137.06 242.26L118.85 219.91L141.17 201.66L159.43 224.01ZM137.32 240.01L157.22 223.75L140.95 203.91L121.06 220.16L137.32 240.01ZM151.75 223.2L137.87 234.55L126.52 220.66L140.4 209.32L151.75 223.2ZM138.06 232.38L149.51 223.02L140.15 211.57L128.73 220.91L138.06 232.38Z"
          fill="#FFFEFD"
        />
        <path
          id="Vector_27"
          d="M51.2998 190.14C51.2978 187.578 52.0559 185.073 53.4781 182.942C54.9003 180.81 56.9227 179.149 59.2894 178.168C61.6561 177.186 64.2608 176.929 66.7738 177.428C69.2867 177.928 71.5951 179.161 73.4068 180.973C75.2185 182.785 76.4521 185.093 76.9515 187.606C77.4508 190.119 77.1935 192.724 76.2121 195.09C75.2307 197.457 73.5694 199.479 71.4382 200.902C69.3071 202.324 66.8019 203.082 64.2398 203.08C60.8128 203.064 57.5306 201.696 55.1073 199.273C52.684 196.849 51.3156 193.567 51.2998 190.14ZM64.2398 182.03C62.6358 182.03 61.0678 182.506 59.7341 183.397C58.4005 184.288 57.361 185.555 56.7471 187.036C56.1333 188.518 55.9727 190.149 56.2856 191.722C56.5986 193.295 57.371 194.74 58.5052 195.875C59.6394 197.009 61.0844 197.781 62.6576 198.094C64.2308 198.407 65.8615 198.246 67.3434 197.633C68.8253 197.019 70.0919 195.979 70.983 194.646C71.8742 193.312 72.3498 191.744 72.3498 190.14C72.3472 187.99 71.4919 185.929 69.9715 184.408C68.4512 182.888 66.3899 182.033 64.2398 182.03Z"
          fill="#FF5331"
        />
        <path
          id="Vector_28"
          d="M5.06006 260.99C5.05809 259.185 5.59154 257.421 6.59289 255.919C7.59425 254.418 9.0185 253.248 10.6854 252.556C12.3522 251.865 14.1868 251.683 15.9568 252.035C17.7269 252.386 19.3528 253.255 20.6289 254.531C21.9049 255.807 22.7737 257.433 23.1253 259.203C23.4769 260.973 23.2954 262.808 22.6039 264.475C21.9124 266.142 20.7419 267.566 19.2406 268.567C17.7393 269.569 15.9747 270.102 14.1701 270.1C11.7548 270.097 9.43913 269.137 7.73124 267.429C6.02336 265.721 5.06271 263.405 5.06006 260.99ZM14.1701 255.27C13.0383 255.268 11.9314 255.602 10.9895 256.229C10.0475 256.856 9.31281 257.749 8.87835 258.794C8.44388 259.839 8.32918 260.99 8.54876 262.1C8.76833 263.21 9.31232 264.23 10.1119 265.031C10.9114 265.832 11.9306 266.378 13.0405 266.599C14.1503 266.821 15.3009 266.708 16.3467 266.276C17.3925 265.843 18.2864 265.11 18.9154 264.169C19.5443 263.228 19.8801 262.122 19.8801 260.99C19.8774 259.474 19.2739 258.02 18.2018 256.948C17.1297 255.876 15.6763 255.273 14.1601 255.27H14.1701Z"
          fill="#B87F7F"
        />
        <path
          id="Vector_29"
          d="M37.0788 214.234L26.6274 221.449L33.8427 231.9L44.294 224.685L37.0788 214.234Z"
          fill="#545389"
        />
        <path
          id="Vector_30"
          d="M94.5898 230.14C96.8431 230.14 98.6698 228.313 98.6698 226.06C98.6698 223.807 96.8431 221.98 94.5898 221.98C92.3364 221.98 90.5098 223.807 90.5098 226.06C90.5098 228.313 92.3364 230.14 94.5898 230.14Z"
          fill="#545389"
        />
        <path
          id="Vector_31"
          d="M42.48 264.74C45.6666 264.74 48.25 262.157 48.25 258.97C48.25 255.783 45.6666 253.2 42.48 253.2C39.2933 253.2 36.71 255.783 36.71 258.97C36.71 262.157 39.2933 264.74 42.48 264.74Z"
          fill="#FF5331"
        />
        <path
          id="Vector_32"
          d="M532.25 138.15C535.315 138.15 537.8 135.665 537.8 132.6C537.8 129.535 535.315 127.05 532.25 127.05C529.185 127.05 526.7 129.535 526.7 132.6C526.7 135.665 529.185 138.15 532.25 138.15Z"
          fill="#FF5331"
        />
        <path
          id="Vector_33"
          d="M464.78 153.95C467.707 153.95 470.08 151.577 470.08 148.65C470.08 145.723 467.707 143.35 464.78 143.35C461.853 143.35 459.48 145.723 459.48 148.65C459.48 151.577 461.853 153.95 464.78 153.95Z"
          fill="#7675A1"
        />
        <path
          id="Vector_34"
          d="M499.92 378.82H474.76C474.847 376.472 475.536 374.186 476.76 372.18C477.961 371.456 479.363 371.137 480.76 371.27C485.948 371.335 491.075 372.401 495.86 374.41C499.946 363.49 504.38 352.69 509.16 342.01C510.055 342.606 510.79 343.413 511.3 344.36C511.835 345.843 511.835 347.467 511.3 348.95C510.708 351.051 509.99 353.115 509.15 355.13C506.083 363.05 503.006 370.947 499.92 378.82Z"
          fill="white"
        />
        <path
          id="Vector_35"
          d="M487.33 364.91C487.03 355.98 486.67 346.81 482.88 338.57C487.178 336.635 491.355 334.445 495.39 332.01C499.46 335.24 503.67 338.34 507.98 341.28C508.38 341.54 508.77 341.81 509.14 342.09C504.36 352.757 499.927 363.557 495.84 374.49C491.056 372.481 485.929 371.415 480.74 371.35C479.344 371.217 477.942 371.536 476.74 372.26C477.236 371.414 477.816 370.62 478.47 369.89C480.833 367.348 483.93 365.607 487.33 364.91Z"
          fill="#D65656"
        />
        <path
          id="Vector_36"
          d="M442.65 349.47L442.79 349.36L443.14 349.75C443.582 350.176 443.923 350.696 444.14 351.27C444.5 352.52 443.56 353.75 442.65 354.74C439.31 358.39 435.65 361.74 432.42 365.5C429.168 369.002 426.664 373.129 425.06 377.63L420.24 377.77L420.15 375.31C419.994 375.273 419.84 375.226 419.69 375.17C425.49 365.31 432.35 355.5 442.65 349.47Z"
          fill="white"
        />
        <path
          id="Vector_37"
          d="M420.27 377.77L403.67 378.26L403.33 377.75C403.263 377.724 403.204 377.684 403.155 377.632C403.107 377.58 403.071 377.518 403.05 377.45C402.21 375.6 402.91 373.45 404.1 371.75C406.93 372.01 409.78 372.31 412.61 372.66C413.379 372.741 414.141 372.878 414.89 373.07C416.61 373.57 418.07 374.65 419.77 375.18C419.92 375.236 420.074 375.283 420.23 375.32L420.27 377.77Z"
          fill="white"
        />
        <path
          id="Vector_38"
          d="M417.16 364.35C418.69 356.983 419.818 349.539 420.54 342.05V341.82C425.447 342.275 430.393 342.026 435.23 341.08C437.73 343.85 440.23 346.6 442.77 349.36L442.63 349.47C432.33 355.47 425.47 365.31 419.7 375.17C418 374.64 416.54 373.56 414.82 373.06C414.071 372.868 413.309 372.731 412.54 372.65C409.71 372.3 406.86 372 404.03 371.74C404.205 371.464 404.399 371.2 404.61 370.95C407.707 367.241 412.11 364.864 416.91 364.31L417.16 364.35Z"
          fill="#D65656"
        />
        <path
          id="Vector_39"
          d="M435.76 268.44C434.151 265.832 432.705 263.127 431.43 260.34C429.639 256.135 428.1 251.828 426.82 247.44C419.78 224.86 412.74 202.277 405.7 179.69C422.776 178.31 439.479 173.961 455.06 166.84C460.66 179.96 460.82 194.53 459.36 208.57C457.9 222.61 454.79 236.84 454.36 251.1C457.176 252.633 459.621 254.767 461.52 257.35C479.12 278.86 483.52 307.07 497.66 330.59C496.93 331.06 496.19 331.52 495.43 331.97C491.395 334.404 487.217 336.595 482.92 338.53C482.07 338.92 481.2 339.29 480.33 339.66C469.86 317.44 455.02 297.21 441.1 276.66C439.24 273.91 437.44 271.22 435.76 268.44Z"
          fill="#272742"
        />
        <path
          id="Vector_40"
          d="M435.25 341.08C430.414 342.026 425.467 342.275 420.56 341.82C419.49 341.73 418.43 341.6 417.37 341.43C412.63 303.223 407.883 265.01 403.13 226.79C401.8 215.97 400.44 205.14 398.34 194.42C397.4 189.64 396.313 184.897 395.08 180.19C398.62 180.19 402.17 179.99 405.7 179.69C412.733 202.27 419.773 224.853 426.82 247.44C428.099 251.828 429.639 256.135 431.43 260.34C432.705 263.127 434.151 265.832 435.76 268.44C435.82 269.74 435.83 271.03 435.83 272.32C435.83 286.86 435.64 301.43 436.99 315.92C437.74 324.13 438.99 332.44 437.47 340.56C436.74 340.77 435.99 340.91 435.25 341.08Z"
          fill="#3B3B59"
        />
        <path
          id="Vector_41"
          d="M476.43 10.43C477.139 12.5501 477.411 14.7919 477.23 17.02C476.88 22.67 474.23 28.43 469.15 31.68C463.36 35.39 455.37 35.22 448.9 32.62C446.515 31.6665 444.269 30.3986 442.22 28.85C440.743 27.7185 439.353 26.4784 438.06 25.14C435.89 22.9 433.91 20.49 431.57 18.38C429.251 16.1524 426.401 14.5551 423.29 13.74C420.584 13.0899 417.733 13.4677 415.29 14.8C414.164 14.1819 413.002 13.631 411.81 13.15C411.785 11.3227 412.243 9.5212 413.139 7.92828C414.034 6.33535 415.336 5.00769 416.91 4.08002C420.431 2.03761 424.603 1.42886 428.56 2.38002C431.26 2.99002 433.77 4.20002 436.56 4.55002C442.56 5.32002 448.14 1.92002 454.02 0.550017C458.48 -0.506047 463.154 -0.188829 467.43 1.46002C469.468 2.25396 471.319 3.46065 472.868 5.0043C474.416 6.54795 475.629 8.3952 476.43 10.43Z"
          fill="#272742"
        />
        <path
          id="Vector_42"
          d="M423.61 55.65L423.98 56.02C420.114 53.9484 416.666 51.1783 413.81 47.85C415.174 46.4109 416.18 44.67 416.744 42.769C417.309 40.8681 417.417 38.8606 417.06 36.91C416.892 35.8546 416.451 34.8615 415.78 34.03C415.412 33.6054 414.947 33.2757 414.425 33.0686C413.903 32.8614 413.339 32.783 412.78 32.84C411.887 32.9974 411.087 33.4869 410.54 34.21C410.136 34.7158 409.839 35.2984 409.667 35.9224C409.495 36.5464 409.452 37.1987 409.54 37.84C403.1 39.55 396.19 39.13 389.54 38.15C388.1 37.9598 386.674 37.6792 385.27 37.31C384.112 36.9728 382.987 36.531 381.91 35.99C381.255 35.6992 380.635 35.337 380.06 34.91C379.085 33.605 378.465 32.0699 378.26 30.4541C378.055 28.8384 378.272 27.197 378.89 25.69C380.232 22.558 382.378 19.8361 385.11 17.8C389.44 14.0788 394.809 11.7783 400.49 11.21C404.345 10.9986 408.198 11.6618 411.76 13.15C412.951 13.6309 414.113 14.1819 415.24 14.8C416.232 15.3379 417.197 15.9253 418.13 16.56C421.27 18.5116 423.861 21.2303 425.66 24.46C426.814 26.9693 427.414 29.6979 427.42 32.46C427.68 40.29 425.06 47.91 423.61 55.65Z"
          fill="#272742"
        />
        <path
          id="Vector_43"
          d="M413.81 47.85C416.666 51.1783 420.114 53.9485 423.98 56.02L424.41 56.46C424.03 60.9477 422.062 65.1528 418.86 68.32C417.202 68.8736 415.585 69.5419 414.02 70.32C410.812 71.8843 407.7 73.6374 404.7 75.57C404.512 73.0859 404.178 70.615 403.7 68.17C403.57 67.5 403.44 66.82 403.29 66.17C403.026 64.6505 402.476 63.1949 401.67 61.88C400.264 62.5842 398.781 63.1212 397.25 63.48C396.865 63.5978 396.457 63.6218 396.06 63.55C395.633 63.3939 395.258 63.12 394.98 62.76C393.4 61.0733 391.843 59.3633 390.31 57.63C390.153 57.4268 389.964 57.251 389.75 57.11C389.313 56.9324 388.837 56.8738 388.37 56.94C386.15 57.04 383.72 56.88 382.03 55.56C381.802 55.4101 381.609 55.2128 381.464 54.9816C381.319 54.7503 381.226 54.4906 381.19 54.22C381.274 53.6205 381.586 53.0763 382.06 52.7L383.85 50.89C384.097 50.7124 384.303 50.4831 384.453 50.218C384.603 49.953 384.693 49.6586 384.718 49.3551C384.742 49.0517 384.701 48.7465 384.596 48.4607C384.491 48.1749 384.325 47.9153 384.11 47.7C381.816 43.5274 380.243 38.9969 379.46 34.3C379.647 34.5123 379.848 34.7127 380.06 34.9C380.605 35.3353 381.195 35.7108 381.82 36.02C382.898 36.561 384.022 37.0029 385.18 37.34C386.584 37.7092 388.01 37.9898 389.45 38.18C396.08 39.18 402.99 39.58 409.45 37.87C409.362 37.2288 409.406 36.5764 409.577 35.9524C409.749 35.3284 410.046 34.7458 410.45 34.24C411.016 33.521 411.835 33.0453 412.74 32.91C413.299 32.8531 413.863 32.9314 414.385 33.1386C414.907 33.3457 415.372 33.6755 415.74 34.1C416.415 34.9074 416.869 35.8753 417.06 36.91C417.417 38.8606 417.309 40.8681 416.745 42.7691C416.18 44.67 415.174 46.4109 413.81 47.85Z"
          fill="#F7B05C"
        />
        <path
          id="Vector_44"
          d="M455.06 166.81H455C439.432 173.927 422.742 178.275 405.68 179.66C402.15 179.96 398.6 180.12 395.06 180.16H393.39C392.76 161.67 392.07 140.35 390.84 121.03C390.38 117.61 388.71 98.71 390.53 92.87C392.03 88.07 394.14 83.15 398.12 79.59C400.462 77.6945 403.101 76.1978 405.93 75.16C408.703 74.0309 411.379 72.6764 413.93 71.11C418.43 68.26 422.18 64.36 423.84 59.58C424.116 58.5457 424.293 57.4877 424.37 56.42C425.088 57.123 425.756 57.8748 426.37 58.67C429.203 61.9443 431.69 65.5033 433.79 69.29C437.426 75.9264 439.921 83.1267 441.17 90.59C443.39 103.71 441.92 117.22 438.17 130.04C437.757 130.931 437.615 131.924 437.763 132.895C437.91 133.866 438.341 134.772 439 135.5C445.267 145.442 450.642 155.92 455.06 166.81Z"
          fill="#E14954"
        />
        <path
          id="Vector_45"
          d="M368.06 98.04C374.75 90.94 381.45 83.85 388.64 77.18C392.92 73.18 397.75 69.18 403.74 68.18C404.218 70.625 404.551 73.0959 404.74 75.58C395.1 81.72 386.65 89.46 378.86 97.58C377.272 99.4603 375.366 101.048 373.23 102.27C357.05 102.917 340.847 102.703 324.69 101.63C338.82 98.74 353.67 100.02 368.06 98.04Z"
          fill="#FF5331"
        />
        <path id="Vector_46" d="M390.3 113.01L423.24 88.76L393.41 180.2L390.3 113.01Z" fill="#A9373F" />
        <path
          id="Vector_47"
          d="M398.83 56.91C398.83 58.21 398.12 60.11 396.29 60.39C395.329 60.4709 394.367 60.247 393.54 59.75C392.763 59.3236 392.066 58.7653 391.48 58.1C391.452 58.0561 391.434 58.0068 391.427 57.9553C391.419 57.9039 391.423 57.8515 391.437 57.8015C391.451 57.7515 391.476 57.705 391.509 57.6652C391.542 57.6253 391.583 57.5928 391.63 57.57C391.673 57.5311 391.72 57.4975 391.77 57.47L397.09 54.47H397.22C397.276 54.4205 397.342 54.3827 397.412 54.3589C397.483 54.335 397.558 54.3256 397.633 54.3312C397.707 54.3368 397.78 54.3573 397.847 54.3914C397.913 54.4256 397.972 54.4727 398.02 54.53C398.525 55.2229 398.808 56.0529 398.83 56.91Z"
          fill="white"
        />
        <path
          id="Vector_48"
          d="M391.29 46.32C391.195 46.5626 391.043 46.7793 390.849 46.953C390.654 47.1266 390.422 47.2523 390.17 47.32C389.894 47.3815 389.606 47.3695 389.336 47.2853C389.066 47.2011 388.823 47.0476 388.63 46.84C388.468 46.6915 388.32 46.5274 388.19 46.35C388.13 46.35 386.67 43.96 387.68 43.04C388.53 42.26 389.86 42.82 390.57 43.47C390.957 43.8168 391.22 44.2802 391.32 44.79C391.463 45.2916 391.453 45.8245 391.29 46.32Z"
          fill="#272742"
        />
        <path
          id="Vector_49"
          d="M389.74 39.34C388.197 39.0732 386.609 39.3413 385.24 40.1C385.122 40.1619 385.017 40.2468 384.933 40.3496C384.848 40.4524 384.785 40.5711 384.747 40.6988C384.708 40.8264 384.696 40.9604 384.71 41.0928C384.725 41.2252 384.765 41.3535 384.83 41.47C384.992 41.701 385.232 41.8656 385.506 41.9336C385.779 42.0016 386.069 41.9684 386.32 41.84C386.524 41.7281 386.734 41.6279 386.95 41.54H387.02H387.09L387.33 41.47L387.72 41.37L388.11 41.3H388.3C388.566 41.2801 388.834 41.2801 389.1 41.3H389.19C389.466 41.3631 389.756 41.3277 390.01 41.2C390.184 41.1 390.323 40.95 390.411 40.7696C390.498 40.5892 390.529 40.3866 390.5 40.1883C390.471 39.9899 390.383 39.8049 390.247 39.6572C390.112 39.5095 389.935 39.4059 389.74 39.36V39.34Z"
          fill="#272742"
        />
        <path
          id="Vector_50"
          d="M346.47 80.85L333.67 135.17C332.08 132.6 330.53 130.04 328.94 127.48C327.592 125.011 325.909 122.74 323.94 120.73C318.83 115.87 310.94 114.92 304.05 116.55C297.16 118.18 291.05 122.01 285.1 125.79L272.41 133.79L271.9 134.1C269.09 129.28 266.11 124.54 263.02 119.87C259.93 115.2 256.15 110.11 250.62 108.59C246.48 107.46 242.06 108.59 237.99 110C229.625 112.938 221.84 117.321 214.99 122.95L231.31 53.69L346.47 80.85ZM276.13 87.43C276.879 84.2563 276.67 80.9319 275.529 77.8771C274.389 74.8222 272.369 72.174 269.724 70.2672C267.078 68.3604 263.927 67.2807 260.669 67.1644C257.41 67.0482 254.19 67.9007 251.416 69.6142C248.641 71.3277 246.437 73.8252 245.082 76.7911C243.727 79.7569 243.282 83.058 243.802 86.2769C244.323 89.4959 245.786 92.4882 248.007 94.8757C250.228 97.2631 253.107 98.9385 256.28 99.69C258.389 100.191 260.575 100.271 262.715 99.9253C264.854 99.58 266.904 98.816 268.748 97.6772C270.592 96.5384 272.193 95.0472 273.46 93.2888C274.726 91.5305 275.634 89.5395 276.13 87.43Z"
          fill="#DCDCEA"
        />
        <path
          id="Vector_51"
          d="M333.67 135.17L346.47 80.85L231.35 53.72L215.06 122.98L211.59 137.69L281.89 154.25L326.71 164.81L333.67 135.17ZM355.82 76.91L332.93 174.04L203.06 143.4L225.94 46.28L355.82 76.91Z"
          fill="#F6FAFD"
        />
        <path
          id="Vector_52"
          d="M333.67 135.17L326.67 164.81L281.86 154.25L282.16 152.98C278.97 146.59 275.57 140.28 271.92 134.13L272.43 133.82L285.12 125.82C291.12 122.04 297.21 118.22 304.07 116.58C310.93 114.94 318.85 115.9 323.96 120.76C325.929 122.77 327.611 125.041 328.96 127.51C330.53 130.04 332.06 132.6 333.67 135.17Z"
          fill="#7172AA"
        />
        <path
          id="Vector_53"
          d="M282.16 152.98L281.86 154.25L211.56 137.69L215.06 122.98C221.91 117.351 229.695 112.968 238.06 110.03C242.13 108.58 246.55 107.49 250.69 108.62C256.22 110.14 259.93 115.12 263.09 119.9C266.25 124.68 269.16 129.31 271.97 134.13C275.57 140.28 278.97 146.59 282.16 152.98Z"
          fill="#4E4F94"
        />
        <path
          id="Vector_54"
          d="M263.85 67.58C267.03 68.3161 269.921 69.9791 272.156 72.3585C274.391 74.7379 275.87 77.7268 276.406 80.947C276.941 84.1672 276.51 87.474 275.166 90.4489C273.822 93.4238 271.625 95.9331 268.855 97.6594C266.084 99.3856 262.863 100.251 259.601 100.147C256.338 100.042 253.179 98.9719 250.525 97.0717C247.871 95.1715 245.839 92.5267 244.689 89.4718C243.538 86.4169 243.319 83.0893 244.06 79.91C244.549 77.8005 245.449 75.808 246.708 74.0465C247.968 72.2849 249.562 70.7889 251.4 69.6438C253.238 68.4987 255.283 67.727 257.42 67.3729C259.556 67.0188 261.741 67.0892 263.85 67.58Z"
          fill="#7172AA"
        />
        <path
          id="Vector_55"
          d="M323.11 102.65C321.636 106.197 320.163 109.743 318.69 113.29C313.493 113.983 308.232 114.047 303.02 113.48C301.696 113.441 300.407 113.055 299.28 112.36C297.28 110.88 297.1 108 297.28 105.52C297.42 102.89 297.82 100.02 299.76 98.24C300.029 97.9612 300.379 97.7725 300.76 97.7C301.104 97.676 301.448 97.7415 301.76 97.89C305.16 99.23 308.16 102.12 311.76 101.94C311.32 100.21 310.88 98.47 310.45 96.74C310.385 96.5971 310.351 96.442 310.351 96.285C310.351 96.1281 310.385 95.9729 310.45 95.83C310.72 95.44 311.33 95.56 311.77 95.75C315.83 97.54 318.84 101.49 323.11 102.65Z"
          fill="#F7B05C"
        />
        <path
          id="Vector_56"
          d="M369.06 102.29C371.043 101.044 372.79 99.4557 374.22 97.6C381.34 89.48 389.08 81.74 397.9 75.6C400.629 73.6757 403.474 71.9224 406.42 70.35C407.846 69.5769 409.327 68.9085 410.85 68.35C413.116 67.4535 415.572 67.1439 417.99 67.45C419.228 67.6169 420.402 68.0989 421.4 68.85C421.78 69.1595 422.117 69.5195 422.4 69.92C423.413 71.4192 423.85 73.2341 423.63 75.03C423.282 77.2968 422.409 79.4508 421.08 81.32C417.08 87.58 411.08 92.32 405.27 96.97L392.63 107.01C390.63 108.61 388.63 110.21 386.52 111.73C385.92 112.17 385.32 112.59 384.72 113.01C381.36 115.37 377.85 117.52 374.28 119.54L374.18 119.36C372.325 119.87 370.401 120.083 368.48 119.99C351.6 119.86 335.04 115.19 318.17 114.54C318.34 114.12 318.52 113.71 318.69 113.29C320.163 109.743 321.637 106.197 323.11 102.65L323.56 101.58L324.65 101.65C339.428 102.723 354.257 102.937 369.06 102.29Z"
          fill="#E14954"
        />
      </g>
      <defs>
        <clipPath id="clip0_108_73">
          <rect width="584.09" height="378.9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
