import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const Logout = () => {
  const navigate = useNavigate();
  const cookies = Cookies.get("logoutURL");
  useEffect(() => {
    Cookies.remove("authToken", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("username", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    Cookies.remove("email", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // navigate("/");
    window.location.href = cookies;
  });
  return null;
};

export default Logout;
