import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { makeAspectCrop, centerCrop, convertToPixelCrop } from "react-image-crop";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Spinner } from "reactstrap";

//^ http request
import { setProfileImgHandler, getProfileImageHandler, resetProfileImgHandler } from "../http/post-api";
import { queryClient } from "../http";

//^ lib
import { setCanvasPreview } from "../utils/lib";
import { base64toFile, blobToFile } from "../utils/Utils";

//^ svg
import UploadFileSvg from "../components/svg/upload-file";

import { Box, Button, Container, DialogActions, DialogContent, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 240;

const ImageCropper = ({ originalImgSrc, updateAvatar, handleModalClose, getOriginalImgFile, onModalClose }) => {
  const theme = useTheme();

  const [crop, setCrop] = useState();
  const [imageSrc, setImageSrc] = useState(originalImgSrc || "");
  const [dataUrl, setDataUrl] = useState("");
  const [originalImgFile, setOriginalImgFile] = useState(undefined);
  const [alreadyImagePresent, setAlreadyImagePresent] = useState(false);

  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const selectFileRef = useRef(null);

  React.useEffect(() => {
    if (originalImgFile) {
      getOriginalImgFile(originalImgFile);
    }
    // eslint-disable-next-line
  }, [originalImgFile]);

  //^ get original profile img if it is exists
  const {
    data: getOriginalImgData,
    isLoading: getOriginalImgIsLoading,
    isRefetching: getOriginalImgIsRefetching,
    isError: getOriginalImgIsError,
    error: getOriginalImgError,
    refetch: getOriginalImgRefetch,
  } = useQuery({
    queryKey: ["get-original-profile-img"],
    queryFn: async () => getProfileImageHandler({ type: "crop" }),
    enabled: true,
    retry: false,
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    (async () => {
      if (!getOriginalImgIsLoading || !getOriginalImgIsRefetching) {
        if (!getOriginalImgData) {
          if (getOriginalImgData?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        } else {
          const imageUrl = URL.createObjectURL(getOriginalImgData);
          if (imageUrl) {
            const originalImageFile = await blobToFile(imageUrl, "original-profile-img.png");

            setImageSrc(imageUrl);
            setOriginalImgFile(originalImageFile);
            setAlreadyImagePresent(true);
          } else {
            setAlreadyImagePresent(false);
            setOriginalImgFile(originalImgFile);
            setImageSrc(imageSrc);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [getOriginalImgIsLoading, getOriginalImgIsRefetching, getOriginalImgData]);

  useEffect(() => {
    if (getOriginalImgIsError) {
      console.log(getOriginalImgError?.info);
    }
  }, [getOriginalImgIsError, getOriginalImgError]);

  //^ set profile mutation query
  const {
    isPending: setProfileImgIsPending,
    isError: setProfileImgIsError,
    error: setProfileImgError,
    mutate: setProfileImgMutate,
    reset: setProfileImgReset,
  } = useMutation({
    mutationKey: ["set-profile-img"],
    mutationFn: setProfileImgHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          queryClient.invalidateQueries(["get-profile-image"]);
          queryClient.invalidateQueries(["get-profile"]);
          getOriginalImgRefetch();
          handleModalClose(false);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            toast.error(data.message);
          }
        }
      }

      setProfileImgReset();
    },
  });

  //^ remove profile image mutation query
  const {
    isPending: resetImgIsPending,
    isError: resetImgIsError,
    error: resetImgError,
    mutate: resetImgMutate,
    reset: resetImgReset,
  } = useMutation({
    mutationKey: ["reset-profile-img-handler"],
    mutationFn: resetProfileImgHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          toast.success(data.message);
          queryClient.invalidateQueries(["get-profile-image"]);
          queryClient.invalidateQueries(["get-profile"]);
          getOriginalImgRefetch();
          handleModalClose(false);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            toast.error(data.message);
          }
        }
      }
      resetImgReset();
    },
  });

  useEffect(() => {
    if (resetImgIsError) {
      console.log(resetImgError?.info);
    }
  }, [resetImgIsError, resetImgError]);

  useEffect(() => {
    if (setProfileImgIsError) {
      toast.error(setProfileImgError?.message || "Something went wrong");
      setProfileImgReset();
    }
  }, [setProfileImgIsError, setProfileImgError, setProfileImgReset]);

  useEffect(() => {
    updateAvatar(dataUrl, imageSrc);

    // eslint-disable-next-line
  }, [dataUrl, imageSrc, updateAvatar]);

  const handleSelectFile = (event) => {
    const file = event.target.files?.[0];
    setOriginalImgFile(file);
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      setImageSrc(imageUrl);

      imageElement.addEventListener("load", (event) => {
        const imageElement = event.currentTarget;

        const { naturalWidth, naturalHeight } = imageElement;

        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          toast.error("Image must be at least 240 x 240 pixels.");
          return setImageSrc("");
        }
      });
    });

    reader.readAsDataURL(file);
  };

  const handleReactCrop = (_crop, percentageCrop) => {
    setCrop(percentageCrop);
  };

  const handleImageLoad = (event) => {
    const { width, height } = event.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);

    setCrop(centeredCrop);
  };

  const handleCropImage = () => {
    setCanvasPreview(
      imageRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
    );

    const dataUrl = previewCanvasRef.current?.toDataURL();
    setDataUrl(dataUrl);

    const profileImg = base64toFile(dataUrl, "profile-img");
    setProfileImgMutate({ cropFile: profileImg, originalFile: originalImgFile });
  };

  function handleClose() {
    onModalClose(false);
  }

  function removePhotoHandler() {
    resetImgMutate({ resetImg: 1 });
  }

  return (
    <>
      <DialogContent dividers sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
        <Stack height={"100%"}>
          <Stack gap={"1rem"} alignItems={"center"} height={"100%"} justifyContent={"center"}>
            {imageSrc ? (
              <>
                <Container maxWidth={"md"} height={"100%"}>
                  <Stack gap={"1.25rem"} height={"100%"}>
                    <ReactCrop
                      crop={crop}
                      onChange={handleReactCrop}
                      circularCrop
                      keepSelection
                      aspect={ASPECT_RATIO}
                      minWidth={MIN_DIMENSION}
                    >
                      <img
                        ref={imageRef}
                        src={imageSrc}
                        alt="upload-img"
                        style={{ objectFit: "contain", maxHeight: "60vh", width: "100%" }}
                        onLoad={handleImageLoad}
                      />
                    </ReactCrop>
                  </Stack>
                </Container>
              </>
            ) : (
              <>
                {getOriginalImgIsLoading ? (
                  <Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Spinner size={"large"} style={{ borderWidth: "2px", color: "inherit" }} />
                  </Stack>
                ) : (
                  <UploadFileSvg />
                )}
              </>
            )}
            {!getOriginalImgIsLoading || !getOriginalImgIsRefetching ? (
              <Stack justifyContent={"center"} direction={"row"} gap={"0.625rem"} alignItems={"center"}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                  <Button
                    onClick={() => selectFileRef.current.click()}
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "100rem", whiteSpace: "nowrap" }}
                  >
                    Choose Photo
                  </Button>
                </Stack>
                <Box>
                  <input
                    type="file"
                    accept="image/*"
                    id="image-cropper"
                    onChange={handleSelectFile}
                    ref={selectFileRef}
                  />
                </Box>
              </Stack>
            ) : (
              ""
            )}

            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                width: "15rem",
                height: "15rem",
                objectFit: "contain",
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ padding: theme.spacing(2) }}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} gap={"1rem"} width={"100%"}>
          <Stack
            direction={"row"}
            width={"100%"}
            gap={"0.625rem"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              "@media (min-width: 66.5rem)": {
                justifyContent: "flex-start",
                alignItems: "flex-start",
              },
            }}
          >
            <Button
              startIcon={
                setProfileImgIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
              }
              variant="contained"
              type="button"
              onClick={handleCropImage}
              disabled={setProfileImgIsPending || !imageSrc}
              sx={{ whiteSpace: "nowrap" }}
            >
              {alreadyImagePresent ? "Update Profile" : "Upload Profile"}
            </Button>
            {alreadyImagePresent ? (
              <Box>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={removePhotoHandler}
                  disabled={resetImgIsPending}
                  sx={{ whiteSpace: "nowrap" }}
                  endIcon={
                    resetImgIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                  }
                >
                  Remove Photo
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Stack>

          <Stack
            sx={{
              display: "none",
              "@media (min-width: 65.5rem)": {
                display: "flex",
              },
            }}
            direction={"row"}
            alignItems={"center"}
            gap={"0.625rem"}
          >
            <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
};

export default ImageCropper;
