import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import { countries } from "country-data";
import { toast } from "react-toastify";
import * as yup from "yup";

//^ http requests
import { postSet2FAProfileHandler } from "../../../../../http/post-api";

//^ helper funcs
import { getCookie } from "../../../../../utils/Utils";
import { useTheme } from "../../../../../layout/provider/Theme";

//^ stylesheet
import classes from "./TwoFactAuth.module.scss";

//^ mui
import { Box, Stack, Button, CircularProgress } from "@mui/material";

//^ components
import { Icon } from "../../../../../components/Component";
import ErrorModel from "../../../../../components/ui/modals/error-model/ErrorModel";
import ResendOtpTimer from "../components/ResendOtpTimer";
import BothOtpVerify from "./BothOtpVerify";
import { useSelector } from "react-redux";

export default function BothTwoFactorAuth() {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  //^ redux selector
  const userProfileData = useSelector((state) => state.userProfile.profileData);
  const userProfile = useSelector((state) => state.twoFactorUserProfile.userProfile);

  const countryIso = userProfile?.profile_iso || "US";

  //^ error handling states
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [bothOtpVerificationErrorModel, setBothOtpVerificationErrorModel] = useState(false);
  //^ disable states
  const [phoneInputIsDisabled, setPhoneInputIsDisabled] = useState(
    userProfileData?.phonecode && userProfileData.two_fact_phone ? true : userProfileData.phone_number ? true : false
  );
  const [emailInputIsDisabled, setEmailInputIsDisabled] = useState(true);

  //^ other states
  const [bothOtpVerificationData, setBothOtpVerificationData] = useState(undefined);
  const [showOtpTimer, setShowOtpTimer] = useState(false);

  //^ boolean states
  const [isCompleted, setIsCompleted] = useState(true);

  const schema = yup.object().shape({
    email: yup.string().email("Email ID must be valid email").required("Email ID is required"),
  });

  //^ mutation function =====================================================================
  const {
    isPending: otpVerificationIsPending,
    isError: otpVerificationIsError,
    error: otpVerificationError,
    mutate: otpVerificationMutate,
    reset: otpVerificationReset,
  } = useMutation({
    mutationKey: ["both-otp-verification"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.type === "error") {
        toast.error(data?.message);
      } else if (data.type === "success") {
        setPhoneInputIsDisabled(true);
        setEmailInputIsDisabled(true);

        setShowOtpTimer(true);

        setBothOtpVerificationData(data);

        toast.success(data?.message);
      } else if (data.message === "Validation failed") {
        toast.error(data?.message);
      }
      otpVerificationReset();
    },
  });

  useEffect(() => {
    if (otpVerificationIsError) {
      setBothOtpVerificationErrorModel(true);
    }
  }, [otpVerificationError, otpVerificationIsError]);

  //^ mutation function =====================================================================

  //^ formik declaration ====================================================================
  const emailId = getCookie("email", null);

  const countryCallingCode =
    countries[userProfile?.profile_iso ? userProfile?.profile_iso : "US"]?.countryCallingCodes[0];
  const phoneNumber = userProfile?.phone_number ? userProfile?.phone_number : "";
  const factPhoneCode = userProfile?.two_fact_phone ? userProfile?.two_fact_phone : "";

  const formik = useFormik({
    initialValues: {
      email: emailId,
      phoneNumber: userProfile?.two_fact_phone
        ? `${countryCallingCode}${factPhoneCode}`
        : `${countryCallingCode}${phoneNumber}`,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const phoneNumber = parsePhoneNumber(values.phoneNumber);
      const countryCode = phoneNumber.countryCallingCode;
      const number = phoneNumber.nationalNumber;

      otpVerificationMutate({
        phoneCode: countryCode,
        twoFactAuth: "3",
        twoFactEmail: values.email,
        twoFactPhone: number,
      });

      setPhoneInputIsDisabled(true);
      setEmailInputIsDisabled(true);
    },
  });
  //^ formik declaration =================================================================

  //* Change handlers =======================================================================
  const phoneInputChangeHandler = useCallback(
    (value) => {
      const stringValue = String(value);

      const isValid = isValidPhoneNumber(stringValue);
      formik.setFieldValue("phoneNumber", stringValue);

      if (!isValid) {
        setPhoneInputError(true);
      } else {
        setPhoneInputError(false);
      }
    },
    // eslint-disable-next-line
    [formik.values.phoneNumber]
  );
  //* Change handlers =======================================================================

  const toggleErrorModel = () => {
    setBothOtpVerificationErrorModel(!bothOtpVerificationErrorModel);
  };

  const buttonOtpMessage =
    bothOtpVerificationData && bothOtpVerificationData?.code === 200 && bothOtpVerificationData?.type === "success"
      ? "Resend OTP"
      : "Send OTP";

  return (
    <>
      <ErrorModel
        isOpen={bothOtpVerificationErrorModel}
        toggleOpen={toggleErrorModel}
        title={"Something went wrong"}
        description={"Unable to verify OTP!"}
        onConfirmed={() => {
          toggleErrorModel();
          otpVerificationReset();
        }}
      />
      <Stack gap={"2rem"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Stack
              className="h-100"
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box className="w-100 d-flex flex-column" style={{ gap: "0.225rem" }}>
                <input
                  type="email"
                  name="email"
                  defaultValue={formik.values.email}
                  className={`form-control form-control-sm ${
                    formik.errors.email && formik.touched.email ? "is-invalid" : ""
                  }`}
                  style={{ height: "2.5rem", fontSize: "14px" }}
                  placeholder="Enter a Email ID."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={emailInputIsDisabled}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p id="validationServer03Feedback" className="invalid-feedback">
                    {formik.errors.email}
                  </p>
                ) : (
                  ""
                )}
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="button"
                  onClick={() => setEmailInputIsDisabled(!emailInputIsDisabled)}
                  sx={{ height: "2.5rem" }}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box className="w-100 d-flex flex-column" style={{ gap: "0.225rem" }}>
                <PhoneInput
                  value={formik.values.phoneNumber}
                  onChange={phoneInputChangeHandler}
                  defaultCountry={countryIso}
                  className={`${classes["phone-input"]} ${
                    isDark ? classes["dark-mode"] : ""
                  } form-control form-control-sm ${phoneInputError ? "is-invalid" : ""} w-100 ${
                    phoneInputIsDisabled ? classes["disable-phone-input"] : ""
                  }`}
                  style={{ height: "2.5rem", fontSize: "14px" }}
                  disabled={phoneInputIsDisabled}
                  placeholder="Enter phone number"
                  international
                />
                {phoneInputError ? (
                  <p id="validationServer03Feedback" className="invalid-feedback">
                    Enter valid phone number
                  </p>
                ) : (
                  ""
                )}
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="button"
                  onClick={() => setPhoneInputIsDisabled(!phoneInputIsDisabled)}
                  sx={{ height: "2.5rem" }}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
              {showOtpTimer ? (
                <ResendOtpTimer
                  onOtpTimer={(isCompleted) => {
                    if (isCompleted) {
                      otpVerificationReset();
                      setShowOtpTimer(false);
                      setIsCompleted(isCompleted);
                    } else {
                      setIsCompleted(isCompleted);
                    }
                  }}
                />
              ) : (
                ""
              )}
              <Box>
                <Button
                  variant="contained"
                  color={"primary"}
                  type="submit"
                  className={"d-flex"}
                  style={{
                    gap: "0.5rem",
                    alignItems: "center",
                    cursor: phoneInputError ? "not-allowed" : "",
                    whiteSpace: "nowrap",
                  }}
                  disabled={
                    !formik.isValid || phoneInputError || otpVerificationIsPending || isCompleted === false
                      ? true
                      : false
                  }
                  startIcon={otpVerificationIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                >
                  <span>{buttonOtpMessage}</span>
                </Button>
              </Box>
            </Stack>
          </Stack>
        </form>
        {bothOtpVerificationData && bothOtpVerificationData?.type === "success" ? (
          <BothOtpVerify emailId={formik.values.email} phoneNumber={formik.values.phoneNumber} />
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
