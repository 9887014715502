import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Spinner } from "reactstrap";
import * as yup from "yup";
import { countries } from "country-data";
import { parsePhoneNumber } from "libphonenumber-js";
import { useSelector } from "react-redux";

//^ stylesheets
import classes from "./TwoFactAuth.module.scss";

//^ lib
import { postSet2FAProfileHandler } from "../../../../../http/post-api";
import { queryClient } from "../../../../../http";

//^ ui
import { Icon } from "../../../../../components/Component";
import { Box, Stack, Typography, Button } from "@mui/material";

//^ components
import AuthOtp from "../../../../../components/ui/auth-otp/AuthOtp";
import ErrorModel from "../../../../../components/ui/modals/error-model/ErrorModel";
import ResendOtpTimer from "../components/ResendOtpTimer";
import { useTheme } from "../../../../../layout/provider/Theme";

export default function MobileTwoFactorAuth() {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  const userProfileData = useSelector((state) => state.userProfile.profileData);
  const userProfile = useSelector((state) => state.twoFactorUserProfile.userProfile);

  const countryIso = userProfile?.profile_iso || "US";

  const [phoneInputIsDisabled, setPhoneInputIsDisabled] = useState(
    userProfileData?.phonecode && userProfileData?.two_fact_phone ? true : userProfileData?.phone_number ? true : false
  );

  const [phoneOtpData, setPhoneOtpData] = useState(undefined);
  const [isCompleted, setIsCompleted] = useState(null);
  const [showOtpTimer, setShowOtpTimer] = useState(false);

  //^ error handling state
  const [phoneInputError, setPhoneInputError] = useState(false);
  const [phoneOtpErrorModel, setPhoneOtpErrorModel] = useState(false);

  //^ post send otp mutation query
  const {
    isPending: phoneOtpIsPending,
    isError: phoneOtpIsError,
    error: phoneOtpError,
    mutate: phoneOtpMutate,
    reset: phoneOtpReset,
  } = useMutation({
    mutationKey: ["send-otp-verification-for-mobile"],
    mutationFn: postSet2FAProfileHandler,
    onSuccess: (data) => {
      if (data.type === "error") {
        toast.error(data?.message);
      } else if (data.type === "success") {
        setPhoneInputIsDisabled(true);
        setShowOtpTimer(true);
        setPhoneOtpData(data);
        queryClient.invalidateQueries(["get-user-profile"]);
        toast.success(data?.message);
      } else if (data.message === "Validation failed") {
        toast.error(data?.message);
      }
      phoneOtpReset();
    },
  });

  useEffect(() => {
    if (phoneOtpIsError) {
      setPhoneOtpErrorModel(true);
    } else {
      setPhoneOtpErrorModel(false);
    }
  }, [phoneOtpError, phoneOtpIsError]);

  const toggleOtpRequestModalFail = () => setPhoneOtpErrorModel(!phoneOtpErrorModel);

  const schema = yup.object().shape({
    mobile: yup.string().required(),
  });

  const countryCallingCode =
    countries[userProfile?.profile_iso ? userProfile?.profile_iso : "US"]?.countryCallingCodes[0];
  const phoneNumber = userProfile?.phone_number ? userProfile?.phone_number : "";
  const factPhoneCode = userProfile?.two_fact_phone ? userProfile?.two_fact_phone : "";

  const formik = useFormik({
    initialValues: {
      mobile: userProfile?.two_fact_phone
        ? `${countryCallingCode}${factPhoneCode}`
        : `${countryCallingCode}${phoneNumber}`,
    },
    validationSchema: schema,
    onSubmit(values) {
      const phoneNumber = parsePhoneNumber(values.mobile);
      const countryCode = phoneNumber.countryCallingCode;
      const number = phoneNumber.nationalNumber;

      phoneOtpMutate({ phoneCode: countryCode, twoFactAuth: "2", twoFactPhone: number });
    },
  });

  const phoneInputChangeHandler = (value) => {
    const stringValue = String(value);

    const isValid = isValidPhoneNumber(stringValue);
    formik.setFieldValue("mobile", stringValue);

    if (!isValid) {
      setPhoneInputError(true);
    } else {
      setPhoneInputError(false);
    }
  };

  return (
    <>
      <ErrorModel
        isOpen={phoneOtpErrorModel}
        toggleOpen={toggleOtpRequestModalFail}
        title={"Unable to send a otp request"}
        description={"Something went wrong"}
        onConfirmed={() => {
          toggleOtpRequestModalFail();
          phoneOtpReset();
        }}
      />
      <div className="d-flex flex-column h-100" style={{ gap: "2rem" }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={"1rem"}>
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              width={"100%"}
              gap={"1rem"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Box
                className={`${isDark && phoneInputIsDisabled ? classes["dark-box"] : ""} w-100 d-flex flex-column`}
                style={{ gap: "0.252rem" }}
              >
                <PhoneInput
                  value={formik.values.mobile}
                  onChange={phoneInputChangeHandler}
                  defaultCountry={countryIso}
                  className={`${classes["phone-input"]} ${
                    isDark ? classes["dark-mode"] : ""
                  } form-control form-control-sm ${phoneInputError ? "is-invalid" : ""} w-100 ${
                    phoneInputIsDisabled ? classes["disable-phone-input"] : ""
                  }`}
                  style={{ height: "2.5rem", fontSize: "14px" }}
                  international
                  disabled={phoneInputIsDisabled}
                />
                {phoneInputError ? (
                  <Typography variant="subtitle2" className="text-danger">
                    Enter valid phone number.
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              <Stack alignItems={"flex-end"}>
                <Button
                  variant="contained"
                  sx={{ height: "2.5rem" }}
                  type="button"
                  size="large"
                  onClick={() => setPhoneInputIsDisabled(!phoneInputIsDisabled)}
                >
                  <Icon name="edit" className={"fs-4"} />
                </Button>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={"1rem"} alignItems={"center"}>
              <Button
                variant="contained"
                type={"submit"}
                color="primary"
                disabled={
                  phoneInputError || phoneOtpIsPending || isCompleted === false ? true : false || !formik.isValid
                }
                className={"d-flex"}
                style={{
                  gap: "0.5rem",
                  alignItems: "center",
                  cursor: phoneInputError ? "not-allowed" : "",
                  whiteSpace: "nowrap",
                }}
              >
                <span>
                  {phoneOtpData && phoneOtpData?.code === 200 && phoneOtpData?.type === "success"
                    ? "Resend OTP"
                    : "Send OTP"}
                </span>
                {phoneOtpIsPending ? <Spinner color="light" size={"sm"} /> : ""}
              </Button>
              <Box>
                {showOtpTimer ? (
                  <ResendOtpTimer
                    onOtpTimer={(isCompleted) => {
                      if (isCompleted) {
                        phoneOtpReset();
                        setShowOtpTimer(false);
                        setIsCompleted(isCompleted);
                      } else {
                        setIsCompleted(isCompleted);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
          </Stack>
        </form>
        {phoneOtpData && phoneOtpData?.code === 200 ? (
          <AuthOtp otpSendOn={phoneOtpData?.data?.phone_no} type={"mobile"} />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
